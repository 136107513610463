import type EventEmitter from '#/EventEmitter'
import type { Events, Value } from './types.js'


export const [StreamContext, useStreamEmitter] = createRequiredContext<{
  emitter: EventEmitter<Events>
  values: AnyRecord<string>
  metadata: AnyRecord<string>
  valueAt: (path: string) => Value
}>('StreamContext')

export const useTokenText = streamHook<string>(value => presence(value, () => String(value)), '')
export const useTokenInteger = streamHook(value => presence(value, () => Number.castInt(value)), null)
export const useTokenFloat = streamHook(value => presence(value, () => Number.castFloat(value)), null)
export const useTokenBoolean = streamHook(value => presence(value, () => value === true), false)
export const useTokenPresence = streamHook(noopTrue, false, { once: true })
export const useTokenArrayLength = streamHook(value => Array.isArray(value) ? value.length : 0, 0)




export function useTokenObject<V extends Record<string, unknown> | unknown[]>(path: string, defaultValue: null, options?: HookOptions): V | null
export function useTokenObject<V extends Record<string, unknown> | unknown[]>(path: string, defaultValue: V, options?: HookOptions): V
export function useTokenObject(path: string, defaultValue?: Record<string, unknown> | unknown[], options?: HookOptions): Record<string, unknown> | null
export function useTokenObject(path: string, defaultValue?: Record<string, unknown> | unknown[] | null, options: HookOptions = {}) {
  const { get, set } = Object
  const { once, controlPath } = options
  const { emitter, valueAt } = useStreamEmitter()
  const [object, setObject] = useImmutableTransition(() => set({}, path, valueAt(path) ?? defaultValue))

  useLayoutEffect(() => {
    const off1 = controlPath ?
      emitter.on(controlPath, t => t.value && setObject(() => set({}, path, defaultValue))) :
      noop

    const off2 = emitter.on(path, ({ value }) => {
      setObject(current => current ?
        void set(current, path, value) :
        set({}, path, value)
      )
    }, { once })

    return () => {
      off1()
      off2()
    }
  }, [emitter, path, defaultValue, setObject, get, set, controlPath, once])

  return get(object, path)
}




type UseTokenDebounceOptions = HookOptions & {
  ms?: number
  defaultValue?: boolean
  isEnabled?: boolean
}

export function useTokenDebounce(path: string, defaultValue = false, options: UseTokenDebounceOptions = {}) {
  const {
    ms = 0,
    once,
    controlPath,
    isEnabled,
  } = options

  const { emitter } = useStreamEmitter()
  const [value, setValue] = useStateTransition(defaultValue)

  useLayoutEffect(() => {
    let interval: number | null = null

    function clear() {
      if (interval != null) {
        clearInterval(interval)
        interval = null
      }
    }

    function set() {
      if (!isEnabled) {
        setValue(false)
        return
      }

      clear()
      setValue(true)

      interval = setInterval(() => {
        clear()
        setValue(false)
      }, ms) as unknown as number
    }

    function reset() {
      clear()
      setValue(defaultValue)
    }

    const off1 = controlPath ? emitter.on(controlPath, t => t.value && reset()) : noop
    const off2 = emitter.on(path, set, { once })

    return () => {
      clear()
      off1()
      off2()
    }
  }, [emitter, path, ms, setValue, controlPath, once, defaultValue, isEnabled])

  return value
}





type UseTokenValueOptions<V> = HookOptions & {
  cast: (value: Value) => NoInfer<V> | undefined
}

export function useTokenValue<V>(path: string, defaultValue: V, options: UseTokenValueOptions<V>) {
  const {
    cast,
    once,
    controlPath = 'streaming',
  } = options

  const { emitter, valueAt } = useStreamEmitter()
  const [value, setValue] = useStateTransition(() => cast(valueAt(path)) ?? defaultValue)

  useLayoutEffect(() => {
    const off1 = controlPath ? emitter.on(controlPath, t => t.value && setValue(defaultValue)) : noop
    const off2 = emitter.on(path, t => {
      setValue(cast(t.value) ?? defaultValue)
    }, { once })

    return () => {
      off1()
      off2()
    }
  }, [emitter, path, once, setValue, controlPath, cast, defaultValue])

  return value
}





type HookOptions = {
  once?: boolean
  controlPath?: string | false
}

function streamHook<D>(cast: (value: Value) => D | undefined, baseDefaultValue: D, baseOptions?: HookOptions) {
  return <D2 = D>(path: string, defaultValue?: D2, options: HookOptions = {}) =>
    useTokenValue<D | D2>(path, defaultValue ?? baseDefaultValue, {
      ...baseOptions,
      ...options,
      cast,
    })
}
