export type UpdateFn<T> = (current: T) => T | void

export default function useImmutable<T>(initialValue: T | (() => T)) {
  const [value, setRawValue] = useState(initialValue)

  const setValue = useCallback((updater: UpdateFn<T>) =>
    setRawValue(current => {
      const next = produce(current, updater)
      return Object.isEqual(current, next) ? current : next
    })
  , [])

  return [
    value,
    setValue,
  ] as const
}
