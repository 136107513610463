import Spinner from './Spinner.js'

type SpinnerBlockProps = {
  className?: string
}

const Wrapper = styled.div(`
  p-9
  text-center
`)

export default function SpinnerBlock(props: SpinnerBlockProps) {
  return (
    <Wrapper {...props}>
      <Spinner />
    </Wrapper>
  )
}
