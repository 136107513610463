import type { Actions } from './atom.js'
import type { Tables } from '^/app/store/index'
import type { Traits } from '^/app/store/types'



export const [AssessmentContext, useAssessmentContext] = createRequiredContext<{
  actions: Actions
  assessmentId: Tables.Assessments.Key
  traceId: string
  getInputTokens: () => Dx.Ai.Token[]
  sessionId: Tables.Sessions.Key
  subjectId: Tables.Subjects.Key
  summarize: (() => void)
  traits: {
    current: Traits.ByName
    clear: () => Promise<void>
  },
}>('AssessmentContext')
